import styled, { keyframes } from 'styled-components'
import { transparentize } from 'polished'

const appear = keyframes`
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
`

export const Container = styled.div``

export const DataContainers = styled.div`
  display: grid;
  padding-top: 50px;
  gap: 30px;
`

export const DataContainer = styled.div``

export const DataInfoContainer = styled.div`
  padding-bottom: 15px;
  animation: ${appear} 200ms 1;
  h1 {
    font-size: 1.8rem;
    color: ${props => props.theme.safiraColors.blue[4]};
  }
  h2 {
    font-size: 1.3rem;
    color: ${props => transparentize(0.5, props.theme.safiraColors.blue[4])};
  }
`

export const ShortTermGraficsButton = styled.div`
  margin-top: 8px;
  padding: 5px; /* Adjust padding to fit the icon */
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: rgb(3, 38, 119);
  display: flex;
  align-items: center;
  justify-content: center; /* Center the icon */
  background: rgb(242, 241, 246);
  width: 35px; /* Adjust the width as needed */
  height: 35px; /* Adjust the height as needed */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background 0.3s ease, border-color 0.3s ease; /* Add transition for smooth animation */

  &:hover {
    background: rgb(220, 220, 230); /* Change background color on hover */
    border-color: rgba(0, 0, 0, 0.2); /* Change border color on hover */
  }

  &:active {
    background: rgb(200, 200, 210); /* Change background color on click */
    border-color: rgba(0, 0, 0, 0.3); /* Change border color on click */
  }
`
