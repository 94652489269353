import React, { useState, useEffect } from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts'
import { ModalOverlay, ModalContent, CloseButton, DescriptionContent, ChartContainer } from './styles'
import { BiX } from 'react-icons/bi'
import api from 'services/api'
import { capitalize } from 'lodash'
import { months } from 'utils/globals'
import { format } from 'date-fns'
import { Select } from 'components/Input'
import { LoadingContainer } from 'components/Chart'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  year: number
  month: number
  revision: string
  studyType: string
}

interface MapData {
  cmoAverage: number
}

interface DateData {
  date: string
  maps: {
    [key: string]: MapData
  }
}

interface FinalChartData {
  titles: string[]
  data: DateData[]
}

const fixedColors = [
  '#83d123',
  '#600000',
  '#e56910',
  '#008000',
  '#ff1a75',
  '#9933ff',
  '#4dffb8',
  '#ff1ac6',
  '#ffff00',
  '#f6ad79',
  '#009900',
  '#aa0000',
  '#cc00cc',
  '#666699',
  '#de3400',
  '#1afffb',
  '#ff00c8',
  '#032677',
  '#ff0000',
  '#1f961d',
]

const formatCurrency = (value: number) => {
  return `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
}

const submarkets = [
  { value: 'Sudeste/Centro Oeste', label: '1 - SUDESTE' },
  { value: 'Norte', label: '4 - NORTE' },
  { value: 'Sul', label: '2 - SUL' },
  { value: 'Nordeste', label: '3 - NORDESTE' },
]

const ModalProspectiveStudies: React.FC<ModalProps> = ({ isOpen, onClose, year, month, revision, studyType }) => {
  const [subsystemValue, changeSubsystemValue] = useState<string>(submarkets[0].label)
  const [chartData, setChartData] = useState<FinalChartData | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (isOpen) {
      setLoading(true)
      api
        .get('/prospective-studies-short-term-grafics', {
          params: {
            year,
            month,
            revision,
            studyType: studyType === 'ESTENDIDO' ? 'DEFINITIVO' : studyType,
            extended: studyType === 'ESTENDIDO',
            subsystem: subsystemValue,
          },
        })
        .then(response => {
          const sortedData = response.data.data.sort((a: DateData, b: DateData) => new Date(a.date).getTime() - new Date(b.date).getTime())
          setChartData({ ...response.data, data: sortedData })
        })
        .finally(() => setLoading(false))
    }
  }, [subsystemValue, isOpen, year, month, revision, studyType])

  const handleOverlayClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement
    if (target.id === 'modal-overlay') {
      onClose()
    }
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value
    changeSubsystemValue(submarkets.find(submarket => submarket.value === selectedValue)?.label || submarkets[0].label)
  }

  const tickStyle = {
    fontSize: '12px',
  }

  const formatDescription = (text: string) => {
    return text.replace(/[_-]+/g, ' ')
  }

  if (!isOpen) return null

  return (
    <ModalOverlay id='modal-overlay' onClick={handleOverlayClick} aria-hidden={!isOpen}>
      <ModalContent role='dialog' aria-labelledby='modal-title' aria-describedby='modal-description'>
        <CloseButton onClick={onClose} aria-label='Close modal'>
          <BiX size={30} />
        </CloseButton>

        <DescriptionContent>
          <h1 id='modal-title'>{`${capitalize(months?.[month - 1] ?? '')} ${year}`}</h1>
          <h2 id='modal-description'>
            {revision} - <Select options={submarkets} onChange={handleSelectChange} />
          </h2>
        </DescriptionContent>

        <ChartContainer>
          {loading ? (
            <LoadingContainer />
          ) : (
            <ResponsiveContainer width='100%' height='100%'>
              <LineChart data={chartData?.data} margin={{ top: 20, right: 30, left: 50, bottom: 5 }}>
                <CartesianGrid strokeDasharray='3' strokeOpacity={0.8} />
                <XAxis
                  dataKey='date'
                  allowDuplicatedCategory={false}
                  tickFormatter={date => format(new Date(date), 'dd/MM')}
                  tick={{ ...tickStyle }}
                />
                <YAxis
                  label={{
                    value: 'CMO Médio (R$/MWh) - Sem Limites',
                    angle: -90,
                    position: 'insideLeft',
                    dy: 130,
                  }}
                  tickFormatter={formatCurrency}
                  width={90}
                  tick={{ ...tickStyle }}
                />
                <Tooltip
                  contentStyle={{
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                  }}
                  itemStyle={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                />
                <Legend />
                {chartData?.titles.map((title, index) => {
                  const color = fixedColors[index % fixedColors.length]
                  const formattedTitle = formatDescription(title)
                  return (
                    <Line
                      key={title}
                      dataKey={`maps.${title}.cmoAverage`}
                      name={formattedTitle}
                      type='linear'
                      stroke={color}
                      fill={color}
                      strokeWidth={3}
                      legendType='circle'
                    />
                  )
                })}
              </LineChart>
            </ResponsiveContainer>
          )}
        </ChartContainer>
      </ModalContent>
    </ModalOverlay>
  )
}

export default ModalProspectiveStudies
