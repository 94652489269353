import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

export const ModalContent = styled.div`
  background: white;
  padding: 35px;
  width: 80%;
  height: 70%;
  border-radius: 8px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow: auto;

  @media (max-width: 768px) {
    width: 90%;
    height: 80%;
    padding: 20px;
  }

  @media (max-width: 480px) {
    width: 95%;
    height: 85%;
    padding: 15px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: black;
  z-index: 10000;
`

export const DescriptionContent = styled.div`
  align-self: flex-start;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }

  @media (max-width: 480px) {
    margin-bottom: 10px;
  }
`

export const ChartContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  max-height: 500px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    min-height: 250px;
    max-height: 400px;
  }

  @media (max-width: 480px) {
    min-height: 200px;
    max-height: 350px;
  }
`
